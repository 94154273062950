<template>
  <div class="check_out">
    <div class="order-wrap order-address-edit">
      <div class="d-container">

        <div class="form-box clr">
          <h4 class="order-address-tit">Shipping Address</h4>
          <div class="row">
            <div class="form-item col-sm-6 col-xs-12">
              <label><span>*</span> First Name</label>
              <div>
                <el-input v-model='firstName'></el-input>
              </div>
            </div>
            <div class="form-item col-sm-6 col-xs-12">
              <label><span>*</span> Last Name</label>
              <div>
                <el-input v-model='lastName'></el-input>
              </div>
            </div>
            <div class="form-item col-sm-6 col-xs-12">
              <label><span>*</span> Email address</label>
              <div>
                <el-input v-model='email'></el-input>
              </div>
              <p class="error-text"
                 style="color: #ff0000;margin-top: 5px;font-size:16px;"></p>
            </div>
            <div class="form-item col-sm-6 col-xs-12">
              <label><span>*</span> Phone</label>
              <div>
                <el-input v-model='phone'></el-input>
              </div>
            </div>
            <div class="form-item address-line col-sm-12 col-xs-12">
              <label><span>*</span> Address Line1</label>
              <div>
                <el-input v-model='address1'></el-input>
              </div>
            </div>
            <div class="form-item address-line col-sm-12 col-xs-12">
              <label>Address Line2</label>
              <div>
                <el-input v-model='address2'></el-input>
              </div>
              <p class="note">Note:Provide full shipping address in destination country's language:Room No.&amp; Street Name</p>
            </div>
            <div class="form-item col-sm-6 col-xs-12">
              <label for="region"><span>*</span> Country/Region</label>
              <div class="region-box">

                <el-select v-model="country"
                           placeholder="Please Select"
                           @change='getCountry'>
                  <el-option v-for="item in optionCountry"
                             :key="item.country_id"
                             :label="item.name"
                             :value="item.country_id">
                  </el-option>
                </el-select>

              </div>
            </div>
            <div class="form-item state-box col-sm-6 col-xs-12">
              <label for="country"><span>*</span> State/Country</label>
              <div id="countryContain">
                <!-- 如果上面的country/region选择的国家是没有洲的，则下面显示Input框，反之则显示下拉框 -->
                <el-select v-model="Region"
                           placeholder="Please Select"
                           @change='getRegion'>
                  <el-option v-for="item in optionRegion"
                             :key="item.zone_id"
                             :label="item.name"
                             :value="item.name">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="form-item col-sm-6 col-xs-12">
              <label><span>*</span> City</label>
              <div>
                <el-input v-model='city'></el-input>
              </div>
            </div>
            <div class="form-item col-sm-6 col-xs-12">
              <label><span>*</span> Zip/Postal Code</label>
              <div>
                <el-input v-model='code'></el-input>
              </div>
            </div>
            <div style="clear:both;"></div>
            <div class="check_card ">
              <div class="card_title">Credit Card</div>
              <div class="card_title"><img src="../assets/image/card.jpg"
                     alt=""></div>
              <div class="card_cont row">
                <div class="card_left col-xs-12">Card Number<i style="color:red;margin-right:3px;">* </i></div>
                <div class="card_input col-xs-12">
                  <el-input v-model="number"
                            placeholder="Card Number"></el-input>
                </div>
              </div>
              <div class="card_cont ">
                <div class="card_left">Cardholderr<i style="color:red;margin-right:3px;">* </i></div>
                <div class="card_input">
                  <el-input v-model="cardholderr"
                            placeholder="cardholderr"></el-input>
                </div>
              </div>
              <div class="card_cont ">
                <div class="card_left">CVC/CVV2<i style="color:red;margin-right:3px;">* </i></div>
                <div class="card_input">
                  <div class="inp_box">
                    <el-input v-model="cvc"
                              placeholder="CVC/CVV2"></el-input>
                  </div>
                  <div class="min_img hidden-xs-only"
                       @mouseenter="cardShow=true"
                       @mouseleave="cardShow=false"><img src="../assets/image/picture.jpg"
                         alt=""></div>
                  <div class="big_img"
                       v-show="cardShow"><img src="../assets/image/picture.jpg"
                         alt=""></div>
                </div>
              </div>

              <div class="card_cont ">
                <div class="card_left">Expiration Date<i style="color:red;margin-right:3px;">* </i></div>
                <div class="card_input date_input">
                  <div class="min_select">
                    <el-select v-model="month"
                               placeholder="month">
                      <el-option v-for="item in options1"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.label">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="min_select ">
                    <el-select v-model="year"
                               placeholder="Year">
                      <el-option v-for="item in options2"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.label">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order-box">
            <div class="order-step step4">
              <h4>
                <input type="hidden"
                       id="goodsCount"
                       value="2">
                Item List
              </h4>
              <div class="product-slide">
                <div class="swiper-container swiper-container-horizontal">
                  <div class="swiper-wrapper"
                       style="transform: translate3d(0px, 0px, 0px);">
                    <div class="swiper-slide center swiper-slide-active "
                         style="width: 194px; margin-right: 40px;"
                         v-for="(item,index) in goodsCart"
                         :key="index">
                      <div class="slide-img" style="position:relative;">
                       
                          <img src="https://i.postimg.cc/7Z26Dk23/we-10-64.png" class="slide-img-d"
                               style='height:auto;'>
                          <img :src="item.prospect" alt="" class="slide-img-p">
                        
                        <div class="order-qty">X {{item.count}}</div>
                      </div>
                      <p class="center mt-10"
                         style='font-size:14px;font-weight:600;margin:12px 12px 0;'>{{item.name.en_US}}</p>
                      <!-- <div style="color:#ff0000;text-align:center;display:none;">{ Combo Deals }</div> -->
                    </div>
                  </div>
                  <!-- Add Pagination -->
                  <!-- <div class="swiper-pagination"></div> -->
                  <!-- Add Arrows -->
                  <div class="swiper-button-next swiper-button-disabled"></div>
                  <div class="swiper-button-prev swiper-button-disabled"></div>
                </div>
              </div>
            </div>
            <div class="order-step step5">
              <h4>Check Out</h4>
              <div style="clear:both;"></div>
              <div class="total-box">
                <!-- <div class="t-t">
                  <span class="s-name">Commodity Price</span>
                  <span class="s-value"
                        id="total_goods_price">
                    US $ {{total}}
                  </span>
                </div> -->
                <!-- <div class="t-t">
                <span class="s-name">Shipping Cost</span>
                <span class="s-value">
                  US $ {{shopmethod_flag?'0.00':'22.74'}}</span>
              </div> -->

                <div class="t-t grand-totals"
                     style="border-top:none;text-align:right;">
                  <span class="s-name"
                        style="width:70%;">Grand Total</span>
                  <span class="s-value"
                        id="total_price">
                    US ${{allTotal}}</span>
                </div>
              </div>
              <div class="a-proceed">
                <a @click="payment">Place Your Order</a>
                <!-- <p class="mt-10">Every order you place with us is safe &amp; secure.</p> -->
              </div>
            </div>
          </div>
          <!-- <div class="save-address-btn mt-20 mb-20">
            <button type="submit"
                    @click="saveChange">Save Change</button>
            <a style="font-size: 16px;margin-left: 30px;color: #333;text-decoration: underline;"
               class="a-cancel"
               href="javascript:window.history.go(-1)">Cancel</a>
          </div> -->

          <!-- </form> -->
        </div>
      </div>

    </div>
    <div v-html="payUrl"></div>
  </div>
</template>

<script>
import global from '../utils/global'
export default {
  data () {
    return {
      firstName: '',
      lastName: '',
      goodsCart: [],
      allTotal: 0,
      total: 0,
      month: '',
      year: '',
      number: '',
      cvc: '',
      email: '',
      address1: '',
      address2: '',
      city: '',
      code: '',
      phone: '',
      country: '',
      countryCode1: '',
      countryCode2: '',
      Region: '',
      cardholderr: '',
      optionRegion: '',
      optionCountry: '',
      cardShow: false,
      payUrl: '',
      options1: [{
        value: '1',
        label: 'Month'
      }, {
        value: '2',
        label: '01'
      }, {
        value: '3',
        label: '02'
      }, {
        value: '4',
        label: '03'
      }, {
        value: '5',
        label: '04'
      }, {
        value: '6',
        label: '05'
      },
      {
        value: '7',
        label: '06'
      },
      {
        value: '8',
        label: '07'
      },
      {
        value: '9',
        label: '08'
      },
      {
        value: '10',
        label: '09'
      },
      {
        value: '11',
        label: '10'
      }, {
        value: '12',
        label: '11'
      }, {
        value: '13',
        label: '12'
      },
      ],
      options2: [{
        value: '1',
        label: 'Year'
      }, {
        value: '2',
        label: '2021'
      }, {
        value: '3',
        label: '2022'
      }, {
        value: '4',
        label: '2023'
      }, {
        value: '5',
        label: '2024'
      }, {
        value: '6',
        label: '2025'
      },
      {
        value: '7',
        label: '2026'
      },
      {
        value: '8',
        label: '2027'
      },
      {
        value: '9',
        label: '2028'
      },
      {
        value: '10',
        label: '2029'
      },
      {
        value: '11',
        label: '2030'
      }, {
        value: '12',
        label: '2031'
      },
      ],
    }
  },
  created () {
    let goodsCart = JSON.parse(sessionStorage.getItem('goodsCart')) || []
    this.goodsCart = goodsCart
    this.getTotal()
    this.optionCountry = global.data.country
  },
  methods: {
    payment () {

      let month = this.month
      let year = this.year
      let number = this.number
      let cvc = this.cvc
      let cardholderr = this.cardholderr
      let firstName = this.firstName
      let lastName = this.lastName
      let email = this.email
      let phone = this.phone

      let address1 = this.address1

      let city = this.city
      let code = this.code

      if (month && year && number && cvc && cardholderr) {
        /*currency:币种
            CNY	人民币
            HKD	港币
            USD	美元
            EUR	欧元
            GBP	英镑
            JPY	日元
            TWD	台币
            AUD	澳元

            参数》》》》》》》》》》》》》》》》》》》》》》》
            cardHold	卡持有人姓名
            cardNo	信用卡卡号
            cardExpireMonth	卡有效期月
            cardExpireYear	卡有效期年
            cardSecurityCode	CVV2
            billFirstName	账单姓
            billLastName	账单名
            billAddress	账单联系地址
            billCity	账单城市
            billState	账单省份/州
            billCountry	账单国家
            billZip	账单所在地邮政编码
            billPhone	账单联系电话
            shipFirstName	收货人名
            shipLastName	收货人姓
            shipAddress	收货详细地址
            shipCity	收货城市
            shipState	收货省份/州
            shipCountry	收货国家
            shipZip	收货邮编
            shipPhone	收货电话
            brower	浏览器标识
            ip	ip地址
            email	邮件地址
            currency	币种
            amount	金额(元)

        */
        let brower = this.brower
        let ip = this.ip?this.ip:'2.18.249.59'
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$axios({
          // url: '/tl/insidePay',
          url: '/glo/gloPay',
          method: 'post',
          data: {
            cardHold: cardholderr,
            cardNo: number,
            cardExpireMonth: month,
            cardExpireYear: year,
            cardSecurityCode: cvc,
            // cardType:'AE',
            billFirstName: firstName,
            billLastName: lastName,
            billAddress: address1,
            billCity: city,
            billState: this.Region ? this.Region : this.countryCode1,
            billCountry: this.countryCode1,
            billZip: code,
            billPhone: phone,
            shipFirstName: this.firstName,
            shipLastName: this.lastName,
            shipAddress: this.address1,
            shipCity: this.city,
            shipState: this.Region1 ? this.Region1 : this.countryCode1, //Region2
            shipCountry: this.countryCode1,
            shipZip: this.code,
            shipPhone: phone,
            brower: brower,
            ip: ip,
            email: email,
            currency: 'USD',
            amount: this.allTotal,
            webUrl:'https://www.txalmxj.com',
            billDocument:'1',
            notifyUrl: 'http://www.baidu.com',
            // agentId: '202110110009',
            agentId: '202110110001',
          },
          // headers: {
          //  'Content-type':'application/x-www-form-urlencoded'
          // }
        }).then(res => {
          loading.close();
          if (res.data.code == 200) {
            console.log('www', res.data.data)

            if (res.data.data.respCode == 10000) {
              this.$message({
                message: res.data.data.respMessage,
                type: 'success'
              })
              return
            } else if (res.data.data.respCode == 10001) {
              this.$message({
                message: res.data.data.respMessage,
                type: 'error'
              })
              return
            } else if (res.data.data.respCode == 10002) {
              this.$message({
                message: res.data.data.respMessage,
                type: 'success'
              })
              console.log('url', res.data.data.payUrl)
              if (res.data.data.payUrl) {
                // location.href = res.data.data.payUrl

                window.localStorage.removeItem('callbackHTML')
                window.localStorage.setItem('callbackHTML', res.data.data.payUrl)
                // 读取本地保存的html数据，使用新窗口打开
                var newWin = window.open('', '_blank')
                newWin.document.write(localStorage.getItem('callbackHTML'))
                // 关闭输出流
                newWin.document.close()
              }
            }
            else {
              this.$message({
                message: res.data.data.respMessage,
                type: 'error'
              })
              return
            }

          } else {

            this.$message({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(e => {

          this.$message({
            message: e,
            type: 'error'
          })
          loading.close();
        })
      } else {
        this.$message({
          message: 'Please fill in the required fields！',
          type: 'error'
        })
        return
      }


    },
    getCountry (event) {
      let optionCountry = this.optionCountry
      optionCountry.map(v => {
        if (v.country_id == event) {
          this.countryCode1 = v.iso_code_2
          this.optionRegion = v.zone
          this.Region = ''
          this.countryName = v.name
          console.log('countryName', this.countryName, this.firstName)
          return
        }
      })
    },
    getCountry2 (event) {
      let optionCountry2 = this.optionCountry
      optionCountry2.map(v => {
        if (v.country_id == event) {
          this.countryCode2 = v.iso_code_2
          this.optionRegion2 = v.zone
          this.countryName = v.name
          this.Region2 = ''
          return
        }
      })
    },
    getRegion2 (event) {
      this.Region2 = event
    },
    getRegion (event) {
      this.Region = event
    },
    getTotal () {
      let goodsCart = this.goodsCart
      console.log('goodsCart', goodsCart)

      goodsCart.map((v) => {
        let allPrice
        let price = v.one_price
        allPrice = v.allPrice ? v.allPrice : price
        this.allTotal = allPrice
      })


    },

  },
  mounted () {

    // 获取电脑唯一标识
    // const start = new Date().getTime();
    // let view = document.querySelector('#view');
    // view.innerHTML = '';
    let excludes = {};
    let options = {
      excludes: excludes
    }

    let that = this
    // eslint-disable-next-line no-undef
    Fingerprint2.get(options, function (components) {
      // 参数
      const values = components.map(function (component) {
        return component.value
      });
      // 指纹
      // eslint-disable-next-line no-undef
      const murmur = Fingerprint2.x64hash128(values.join(''), 31);
      // view.innerHTML += '<p class="pp">标识 : ' + murmur + '</p>';
      // sessionStorage.setItem("Finger", murmur); //存入标识值
      console.log(murmur, '浏览器标识');
      that.brower = murmur
      // eslint-disable-next-line no-undef
      that.ip = returnCitySN["cip"]
    });
    // eslint-disable-next-line no-undef
    console.log(returnCitySN["cip"] + ',' + returnCitySN["cname"], 'ip地址')

  }
}
</script>

<style lang='scss' scoped>
@import "../style/common.css";
@import "../style/cart.css";
@import "../style/reset.css";
.slide-img-p{
  position:absolute;
  top:10%;
  left:10%;
  width:80%!important;
  height:auto!important;
}
.check_out {
  width: 100%;
  text-align: left;
  margin-top: 30px;
  /deep/ .el-input__inner {
    width: 100%;
  }
  .el-select {
    width: 100%;
  }
  .ship-flo-left {
    float: left;
    width: 300px;
    &:nth-child(2) {
      width: 500px;
    }
    &:nth-child(3) {
      width: 500px;
      color: #333;
      font-weight: bold;
      margin-left: 15px;
      margin-top: 5px;
    }
  }
  .img_cur {
    border: 2px solid #f7a900;
  }
  .check_card {
    // width:500px;
    color: #444;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    margin-left: 30px;
    .card_title {
      width: 200px;
      font-size: 18px;
      margin-bottom: 10px;
      > img {
        margin-left: 180px;
        width: 100%;
        height: auto;
      }
    }

    .card_cont {
      width: 100%;
      color: #222;
      font-size: 14px;
      display: flex;
      // justify-content: space-between;
      padding: 10px 0;

      .card_left {
        width: 180px;
        text-align: left;
      }

      .card_input {
        display: flex;
        position: relative;

        /deep/ .el-input__inner {
          // height: 34px;
          width: 200px;
        }

        // margin-left:120px;
      }

      .inp_box {
        width: 100px;
      }

      .min_img {
        width: 48px;
        height: 28px;
        margin-left: 120px;
        overflow: hidden;

        > img {
          margin-top: -64px;
          object-fit: cover !important;
          width: auto;
        }
      }

      .big_img {
        width: 250px;
        height: 160px;
        position: absolute;
        overflow: hidden;
        top: 0px;
        right: -300px;
        z-index: 999;

        > img {
          margin-top: -100px;
        }
      }

      .min_select {
        // width: 90px;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .order-wrap {
      padding: 0 15px;
    }
    .card_left {
      width: 100px !important;
    }
    .date_input {
      display: flex;
      flex-direction: column;
      > .min_select {
        margin-bottom: 20px !important;
      }
    }
    .card_title {
      > img {
        margin-left: 0 !important;
      }
    }
    .swiper-container-horizontal {
      padding: 0 !important;
    }
    .swiper-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .swiper-slide {
      width: 168px !important;
      margin-right: 0 !important;
    }
    .s-name {
      width: 50% !important;
    }
    .a-proceed {
      > a {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }
}
</style>